import { Form } from 'dataformjs';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import CircularProgress from '../../../components/base/CircularProgress';
import GridSplit from '../../../components/base/GridSplit';
import Collection from '../../../models/Collection';
import activityQuery from '../../../objects/activities/queries/activity.graphql';
import deliveryData from '../../../objects/deliveries/datas.json';
import { deliveriesCreate as createMutation } from '../../../objects/deliveries/mutations';
import ActivityType from '../../../types/Activity';
import pageProps, { ResultProps } from '../../../utils/pageProps';
import requiredAuth from '../../../utils/requireAuth';

const DeliveriesCreatePage: FC<
  ResultProps & {
    formName?: string;
  }
> = ({ formName = 'delivery', params: { activity: activityId } }) => {
  const [createMutationFunc] = useMutation(createMutation);
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(activityQuery, {
    variables: {
      id: activityId,
    },
  });

  const handleOnSubmit = async (values: any) => {
    console.info('handleOnSubmit values', values);

    const result = await createMutationFunc({
      variables: {
        activityId,
        duration:
          '' !== values.duration && !Number.isNaN(Number(values.duration))
            ? Number(values.duration)
            : undefined,
        isPresenceRequired: Boolean(values.isPresenceRequired),
        placeId: values.place?.id,
      },
    });

    if (!result || result.errors) {
      console.error(result.errors);
    } else {
      const deliveryId = result.data.deliveriesCreate.id;
      window.history.replaceState(
        {
          activityId,
          deliveryId,
        },
        '',
        `/admin/activities/update/${activityId}/`,
      );

      await Collection.initDoc('delivery', deliveryId);
      await Collection.updateDoc('activity', activityId, {
        updatedAt: dayjs().toISOString(),
      });
      await navigate(`/admin/deliveries/update/${deliveryId}/`);
    }
  };

  if (loading) {
    return <CircularProgress fullPage />;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  const activity: ActivityType = data && (data.activity as ActivityType);

  if (!activity) {
    return null;
  }

  return (
    <GridSplit
      header={{
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: t(`delivery.form.create.title`),
      }}
    >
      <Form
        className="mx-6 mb-6"
        datas={deliveryData}
        footerProps={{
          className: 'mt-12 flex justify-end',
        }}
        initialValues={{
          activity,
        }}
        name={formName}
        onSubmit={handleOnSubmit}
        params={{
          activityId,
          spaceId: activity.spaceId,
        }}
        submitProps={{
          label: t(`delivery.form.create.submit`),
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(pageProps(DeliveriesCreatePage), {
  admin: true,
  layout: true,
});
